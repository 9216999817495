/*------------------------------------
  Navigation Style v2
------------------------------------*/

.navbar .u-main-nav-v2 {
	.nav-link {
		@extend %u-main-nav-base-link;

		color: $g-color-gray-dark-v2;

		@include px-to-rem(padding, 9px 30px);
		@include px-to-rem(border-radius, 22px);
		border: 2px solid transparent;
	}

	.nav-item {

		&.hs-has-sub-menu,
		&.hs-has-mega-menu,
		&.dropdown {
			@extend %u-main-nav-has-sub-menu;

			> a {
				@include px-to-rem(padding-right, 45px);

				&::after {
					@include px-to-rem(right, 25px);
				}
			}
		}

		&.active > .nav-link,
		&:hover > .nav-link,
		&:focus > .nav-link,
		&.show > .nav-link,
		&.hs-sub-menu-opened > .nav-link,
		.nav-link:focus {
			color: $g-color-gray-dark-v2;
			border-color: $g-color-primary;
			background-color: transparent;
		}
	}
}

@media all and (max-width: #{$g-xl - 1}) {
	.navbar .u-main-nav-v2 {
		.nav-link {
			@include px-to-rem(padding, 7px 20px);
		}

		.nav-item {
			&.hs-has-sub-menu,
			&.hs-has-mega-menu,
			&.dropdown {
				> a {
					@include px-to-rem(padding-right, 35px);

					&::after {
						@include px-to-rem(right, 15px);
					}
				}
			}
		}
	}
}